

import React from 'react';

const Packages5 = () => {
    const packages = [
        {
            title: 'باقة الـ 8 ساعات',
            weeklyLectures: 'عدد الحصص الإسبوعية : 2',
            duration: 'مدة الحصة : 60 دقيقة',
            price: '874 ريال سعودي',
            offer: 'السعر يشمل ضريبة القيمة المضافة',
            hourlyPrice: 'سعر الساعة : 95 ريال سعودي',
        },
        {
            title: 'باقة الـ 12 ساعة',
            weeklyLectures: 'عدد الحصص الإسبوعية : 3',
            duration: 'مدة الحصة : 60 دقيقة',
            price: '1269.60 ريال سعودي',
            offer: 'السعر يشمل ضريبة القيمة المضافة',
            hourlyPrice: 'سعر الساعة : 92 ريال سعودي',
        },
        {
            title: 'باقة الـ 16 ساعة',
            weeklyLectures: 'عدد الحصص الإسبوعية : 4',
            duration: 'مدة الحصة : 60 دقيقة',
            price: '1637.60 ريال سعودي',
            offer: 'السعر يشمل ضريبة القيمة المضافة',
            hourlyPrice: 'سعر الساعة : 89 ريال سعودي',
        }
    ];

    return (
        <>
            <div className="w-full mt-10">
                <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-[90%] gap-2 lg:mx-16 mx-6 border-[1px] border-[#30B468] rounded-2xl px-10 py-4 mb-10">
                    
                    {/* Static package displayed at the top on mobile screens */}
                    {/* <div className="text-center p-4 rounded-lg transition duration-300 ease-in-out hover:bg-[#EAF7F0] sm:order-first lg:order-last">
                        <h2 className="text-2xl font-bold mb-4">باقة مرحلة الثانوية + اختبارات القدرات والتحصيلي</h2>
                        <img src="graduation1.png" alt="باقة مرحلة التمهيدي" className="lg:w-[200px] lg:pl-[23px] pl-[inherit] h-auto lg:mt-[-18px] mt-[27px] justify-center" />
                    </div> */}

<div className="text-center p-4 rounded-lg transition duration-300 ease-in-out hover:bg-[#EAF7F0] sm:order-first lg:order-last">
    <h2 className="text-2xl font-bold mb-4">باقة مرحلة الثانوية + اختبارات القدرات والتحصيلي</h2>
    <img src="graduation1.png" alt="باقة مرحلة التمهيدي" className="lg:w-[200px] lg:pl-[23px] pl-[inherit] h-auto lg:mt-[-18px] mt-[27px] mx-auto" />
</div>


                    {/* Dynamic packages with custom order on large screens */}
                    {packages.map((pkg, index) => (
                        <div
                            className={`text-center p-4 border-none rounded-lg transition duration-300 ease-in-out hover:bg-[#EAF7F0] ${
                                index === 2 ? 'lg:order-1' : index === 1 ? 'lg:order-2' : 'lg:order-3'
                            }`}
                            key={index}
                        >
                            <h2 className="m1 text-lg font-bold mb-3">{pkg.title}</h2>
                            <h4 className="text-base mb-1">{pkg.weeklyLectures}</h4>
                            <h4 className="text-base mb-10">{pkg.duration}</h4>

                            {/* Price per hour line */}
                            <h4 className="text-base mb-4 bg-[#EAF7F0] rounded-md">{pkg.hourlyPrice}</h4>

                            <div className="mb-4 bg-[#30B468] rounded-3xl py-2 cursor-pointer">
                                <h4 className="text-black">:سعر الباقة</h4>
                                <h3 className="text-lg font-bold text-[#FFEB0C]" style={{ direction: 'rtl' }}>
                                    {pkg.price}
                                </h3>
                            </div>

                            <h6 className="text-sm text-[#939393]">{pkg.offer}</h6>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Packages5;
