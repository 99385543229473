

import React from 'react';

const Packages1 = () => {
    const packages = [
        {
            title: 'باقة الـ 16 ساعة',
            weeklyLectures: 'عدد الحصص الإسبوعية : 4',
            duration: 'مدة الحصة : 60 دقيقة',
            price: '1012 ريال سعودي',
            offer: 'السعر يشمل ضريبة القيمة المضافة',
            hourlyPrice: 'سعر الساعة : 55 ريال سعودي',
        },
        {
            title: 'باقة الـ 12 ساعة',
            weeklyLectures: 'عدد الحصص الإسبوعية : 3',
            duration: 'مدة الحصة : 60 دقيقة',
            price: '897 ريال سعودي',
            offer: 'السعر يشمل ضريبة القيمة المضافة',
            hourlyPrice: 'سعر الساعة : 65 ريال سعودي',
        },
        {
            title: 'باقة الـ 8 ساعات',
            weeklyLectures: 'عدد الحصص الإسبوعية : 2',
            duration: 'مدة الحصة : 60 دقيقة',
            price: '644 ريال سعودي',
            offer: 'السعر يشمل ضريبة القيمة المضافة',
            hourlyPrice: 'سعر الساعة : 70 ريال سعودي',
        }
    ];

    return (
        <>
            <div className="w-full">
                <h1 className="text-center lg:text-3xl text-2xl font-bold mb-14">باقات متنوعة لجميع المراحل</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-[90%] gap-2 lg:mx-16 mx-6 border-[1px] border-[#5C4FD5] rounded-2xl px-10 py-4 mb-10">

                    {/* Static package at the top for mobile */}
                    

                    {/* Loop over the package data */}
                    {packages.map((pkg, index) => (
                        <div
                            className={`text-center p-4 border-none h-[310px] rounded-lg transition duration-300 ease-in-out hover:bg-[#EFEDFB] 
                                ${index === 2 ? 'order-2' : index === 1 ? 'order-3' : 'order-4'} lg:order-none`}
                            key={index}
                        >
                            <h2 className="m1 text-lg font-bold mb-3">{pkg.title}</h2>
                            <h4 className="text-base mb-1">{pkg.weeklyLectures}</h4>
                            <h4 className="text-base mb-10">{pkg.duration}</h4>

                            <h4 className="text-base mb-4 bg-[#EFEDFB] rounded-md">{pkg.hourlyPrice}</h4>

                            <div className="mb-4 bg-[#5C4FD5] rounded-3xl py-2 cursor-pointer">
                                <h4 className="text-white">:سعر الباقة</h4>
                                <h3 className="text-lg font-bold text-[#FFEB0C]" style={{ direction: 'rtl' }}>{pkg.price}</h3>
                            </div>

                            <h6 className="text-sm text-[#939393]">{pkg.offer}</h6>
                        </div>
                    ))}

<div className="text-center p-4 rounded-lg transition duration-300 ease-in-out hover:bg-[#EFEDFB] order-1 lg:order-none">
                        <h2 className="text-2xl font-bold">باقة مرحلة<br/>التمهيدي والابتدائي</h2>
                        <img src="motivate-someone3.png" alt="باقة مرحلة التمهيدي" className="lg:w-[260px] lg:pl-[23px] pl-[inherit] h-auto lg:mt-[-18px] mt-[27px] mx-auto" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Packages1;