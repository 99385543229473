
// import React, { useState, useEffect } from 'react';

// const Home = () => {
//   const texts = [
//     { text: 'التمهيدي (KG)', bgColor: '#2ABBAA' },
//     { text: 'الابتدائي', bgColor: '#FFB800' },
//     { text: 'المتوسط', bgColor: '#AF87F4' },
//     { text: 'الثانوي', bgColor: '#2ABBAA' },
//     { text: 'الجامعي', bgColor: '#FFA800' },
//     { text: 'القدرات والتحصيلي', bgColor: '#5C4FD5' },
//   ];

//   const [currentIndex, setCurrentIndex] = useState(0);

//   // Function to automatically change text and color every second
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
//     }, 1000);

//     return () => clearInterval(interval);
//   }, [texts.length]); // Add texts.length as a dependency

//   const openLink = () => {
//     window.open('https://form.typeform.com/to/uLyKu9PS', '_blank');
//   };

//   return (
//     <div className="w-full flex flex-col items-center lg:min-h-screen min-h-[83vh] relative">
//       {/* Image Section */}
//       <div className="w-full relative">
//         <img src="body.png" alt="Body" className="w-full mb-4 lg:block hidden" />
//         <img src="banner.png" alt="Body" className="w-full mb-4 block lg:hidden" />
//       </div>

//       {/* Content Section */}
//       <div className="flex flex-col items-center justify-center lg:mt-48 mt-14 text-center absolute">
//         <h1 className="lg:text-5xl text-3xl font-bold mb-8">
//           <span className='lg:block hidden'> نوفر لك معلم في جميع التخصصات </span>
//           <span className='block lg:hidden'>نوفر لك معلم في جميع التخصصات لجميع</span>
//           <div className="flex items-center justify-center space-x-4 space-x-reverse mt-5">
//             <button
//               className="text-white lg:px-6 lg:py-3 py-2 px-1 rounded-lg text-3xl transition-all duration-500 mr-1"
//               style={{ minWidth: '200px', backgroundColor: texts[currentIndex].bgColor }}
//             >
//               {texts[currentIndex].text}
//             </button>
//             <span className='lg:block hidden'>لجميع المراحل</span>
//             <span className='block lg:hidden'>المراحل</span>
//           </div>
//         </h1>



// <div className="flex flex-col lg:flex-row justify-center items-center space-y-4 lg:space-y-0 lg:space-x-6 mb-8">
//   {[
//     { text: 'المناهج الوطنية والعالمية', mobileText: 'حصص مباشرة فردية (اونلاين)' },
//     { text: 'مشرفين متخصصين لمتابعة تقدم طفلك', mobileText: 'المناهج الوطنية والعالمية' },
//     { text: 'حصص مباشرة فردية (اونلاين)', mobileText: 'مشرفين متخصصين لمتابعة تقدم طفلك' },
//   ].map((item, index) => (
//     <div
//       key={index}
//       className="flex justify-center items-center p-[1px] rounded-3xl shadow-lg shadow-[gray]/50"
//       style={{
//         background: 'linear-gradient(to bottom, #AF87F4, #ffffff) ',
//       }}
//     >
//       <div className="flex items-center justify-center w-full h-full bg-white rounded-3xl p-2">
//         <p className="flex items-center text-black font-bold text-lg">
//           <span className="lg:block hidden">{item.text}</span>
//           <span className="block lg:hidden">{item.mobileText}</span>
//           <img src="check.png" alt="Check" className="w-6 h-6 ml-2" />
//         </p>
//       </div>
//     </div>
//   ))}
// </div>



//         {/* Button for trial session */}
//         <button className="bg-[#5C4FD5] text-white px-4 py-2 font-medium rounded-3xl text-md mb-3 mt-5 hover:bg-opacity-80"
//           onClick={openLink}>
//           <span>احجز حصتك التجريبية</span>
//         </button>

//         {/* Final text with line */}
//         <div className="text-center mb-8">
//           <p className="text-black font-bold text-md">انضم كمدرس مع هالو</p>
//           <hr className="border-t border-black w-full" />
//         </div>

//         <div className="w-full rounded-3xl lg:px-28 px-4 py-6 flex justify-center relative">
//           <img src="FloatFig.png" alt="Book New One" className='lg:mt-28 max-w-full lg:h-auto h-[175px]' />
//           <div className='absolute flex justify-between lg:w-full lg:px-44 lg:pt-10 pt-0 mt-[-1rem] lg:mt-0 w-[80%]' style={{ top: '50%', transform: 'translateY(-50%)' }}>
//             {[
//               { imgSrc: 'globe5 3.png', count: '+1000', label: 'اشتراكات' },
//               { imgSrc: 'Frame 13.png', count: '+100', label: 'مدرسين\n متخصصين' },
//               { imgSrc: 'book new one 2.png', count: '+500', label: 'محتوى تعليمي'  },
//             ].map((item, index) => (
//               <div key={index} className="text-center">
//                 <img src={item.imgSrc} alt={item.label} className="mx-auto lg:h-20 lg:w-20 w-12 h-12 object-cover" />
//                 <p className="mt-2 bg-gradient-to-r from-[#5C4ED5] to-[#EF9475] text-transparent bg-clip-text font-bold lg:text-4xl text-xl">{item.count}</p>
//                 <p className="mt-1 text-white font-bold text-xs lg:text-base">{item.label}</p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Home;



import React, { useState, useEffect } from 'react';

const Home = () => {
  // const texts = [
  //   { text: 'التمهيدي (KG)', bgColor: '#2ABBAA' },
  //   { text: 'الابتدائي', bgColor: '#FFB800' },
  //   { text: 'المتوسط', bgColor: '#AF87F4' },
  //   { text: 'الثانوي', bgColor: '#2ABBAA' },
  //   { text: 'الجامعي', bgColor: '#FFA800' },
  //   { text: 'القدرات والتحصيلي', bgColor: '#5C4FD5' },
  // ];
  const texts = [
    { text: 'الجامعية', bgColor: '#FFA800' },
    { text: 'الابتدائية', bgColor: '#FFB800' },
    { text: 'التمهيدية KG', bgColor: '#2ABBAA' },
    { text: 'المتوسطة', bgColor: '#AF87F4' },
    { text: 'الثانوية', bgColor: '#2ABBAA' },
    { text: 'القدرات والتحصيلي', bgColor: '#5C4FD5' },
  ];
  
 
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 1000);

    return () => clearInterval(interval);
  }, [texts.length]);

  const openLink = () => {
    window.open('https://form.typeform.com/to/uLyKu9PS', '_blank');
  };

  const openTeacherLink = () => {
    window.open('https://form.typeform.com/to/Dg23LecV', '_blank');
  };

  return (
    <div className="w-full flex flex-col items-center lg:min-h-screen min-h-[83vh] relative -mt-14">
      {/* Image Section */}
      <div className="w-full relative">
        <img src="body1.png" alt="Body" className="w-full lg:block hidden" />
        <img src="banner1.png" alt="Body" className="w-full mt-[-24px] block lg:hidden" />
      </div>

      {/* Content Section */}
      <div className="flex flex-col items-center justify-center lg:mt-24 mt-16 text-center absolute">
        <h1 className="lg:text-5xl text-3xl font-bold mb-8">
          <span className='lg:block hidden'> نوفر لك معلم في جميع التخصصات </span>
          <span className='block lg:hidden'> نوفر لك معلم في جميع التخصصات لجميع المراحل</span>
          <div className="flex items-center justify-center space-x-4 space-x-reverse mt-5">
            <button
              className="text-white lg:px-6 lg:py-3 py-2 px-1 rounded-lg text-3xl transition-all duration-500 mr-1"
              style={{ minWidth: '200px', backgroundColor: texts[currentIndex].bgColor }}
            >
              {texts[currentIndex].text}
            </button>
            <span className='lg:block hidden'>لجميع المراحل</span>
            {/* <span className='block lg:hidden'>المراحل</span> */}
          </div>
        </h1>

        <div className="flex flex-col lg:flex-row justify-center items-center space-y-4 lg:space-y-0 lg:space-x-6 mb-8">
          {[
            { text: 'المناهج الوطنية والعالمية', mobileText: 'حصص مباشرة فردية (اونلاين)' },
            { text: 'مشرفين متخصصين لمتابعة تقدم طفلك', mobileText: 'المناهج الوطنية والعالمية' },
            { text: 'حصص مباشرة فردية (اونلاين)', mobileText: 'مشرفين متخصصين لمتابعة تقدم طفلك' },
          ].map((item, index) => (
            <div
              key={index}
              className="flex justify-center items-center p-[1px] rounded-3xl shadow-lg shadow-[gray]/50"
              style={{
                background: 'linear-gradient(to bottom, #AF87F4, #ffffff)',
              }}
            >
              <div className="flex items-center justify-center w-full h-full bg-white rounded-3xl p-2">
                <p className="flex items-center text-black font-bold text-lg">
                  <span className="lg:block hidden">{item.text}</span>
                  <span className="block lg:hidden">{item.mobileText}</span>
                  <img src="check.png" alt="Check" className="w-6 h-6 ml-2" />
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Button for trial session */}
        <button className="bg-[#5C4FD5] text-white px-4 py-2 font-medium rounded-3xl text-md mb-3 mt-5 hover:bg-opacity-80"
          onClick={openLink}>
          <span>احجز حصتك التجريبية</span>
        </button>

        {/* Final text with line */}
        <div className="text-center mb-8">
          <p className="text-black font-bold text-md cursor-pointer" onClick={openTeacherLink}>انضم كمدرس مع هالو</p>
          <hr className="border-t border-black w-full" />
        </div>

        <div className="w-full rounded-3xl lg:px-28 px-4 py-6 flex justify-center relative">
          <img src="FloatFig.png" alt="Book New One" className='lg:mt-28 max-w-full lg:h-auto h-[175px]' />
          <div className='absolute flex justify-between lg:w-full lg:px-44 lg:pt-10 pt-0 mt-[-1rem] lg:mt-0 w-[80%]' style={{ top: '50%', transform: 'translateY(-50%)' }}>
            {[
              { imgSrc: 'globe5 3.png', count: '+1000', label: 'اشتراكات' },
              { imgSrc: 'Frame 13.png', count: '+100', label: 'مدرسين\n متخصصين' },
              { imgSrc: 'book new one 2.png', count: '+500', label: 'محتوى تعليمي' },
            ].map((item, index) => (
              <div key={index} className="text-center">
                <img src={item.imgSrc} alt={item.label} className="mx-auto lg:h-20 lg:w-20 w-12 h-12 object-cover" />
                <p className="mt-2 bg-gradient-to-r from-[#5C4ED5] to-[#EF9475] text-transparent bg-clip-text font-bold lg:text-4xl text-xl">{item.count}</p>
                <p className="mt-1 text-white font-bold text-xs lg:text-base">{item.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;